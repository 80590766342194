import {Box, Grid, Typography, Link} from '@material-ui/core'
import classNames from 'classnames'
import {useFeature} from 'flagged'
import {Formik} from 'formik'
import React, {ReactNode, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	CustomButtonComponent as CustomButton,
	CustomTextInputComponent as CustomTextInput,
	ErrorMessageBoxComponent as ErrorMessageBox,
	PasswordInputComponent as PasswordInput,
	PlannedMaintenanceBannerComponent,
	TemplateContainerComponent as TemplateContainer,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {CyclisFiguresSmallSvg, CyclisLogoBikeLeaseSvg} from '@svg/index'
import {login} from '@templates/Login/login-slice'
import {loginSchema} from '@templates/Login/login-validation'
import {CONSENT_GIVEN} from '@utils/constants/auth-constants'
import {LanguageContext} from '@utils/context'
import {CommonProps, FeatureFlag} from '@utils/types'

import useStyles from './style.hook'

/**
 * Login
 */

interface LoginFormFields {
	username: string
	password: string
}

const Login: React.FC<CommonProps> = () => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const dispatch = useDispatch()

	const {t} = useTranslation(undefined, {useSuspense: false})
	const {activeLanguage} = useContext(LanguageContext)
	const plannedMaintenanceBannerFeature = useFeature(
		FeatureFlag.PLANNED_MAINTENANCE_BANNER
	)

	const [formValues, setFormValues] = useState<LoginFormFields>({
		username: '',
		password: '',
	})

	const {
		loading: loginLoading,
		success: loginSuccess,
		error: loginError,
		consentGiven,
	} = useSelector((state: RootState) => state.login)

	const submitLogin = async (values: LoginFormFields): Promise<void> => {
		setFormValues(values)
		dispatch(login(values.username, values.password))
	}

	useEffect(() => {
		/**
		 * Navigate to:
		 * 	- home page when loginSuccess == true and consentGiven == true
		 *  - consent page when logicSuccess == true and consentGiven == false
		 */
		if (loginSuccess) {
			localStorage.setItem(CONSENT_GIVEN, consentGiven.toString())
			if (consentGiven) {
				dispatch(navigate(`/${activeLanguage}/app`))
			} else {
				dispatch(navigate(`/${activeLanguage}/app/consent`))
			}
		}
	}, [activeLanguage, loginSuccess, consentGiven])

	const DesktopGrid: React.FC = () => (
		<Grid container spacing={8}>
			<Grid item xs={4}>
				<PurpleSidePanel />
			</Grid>
			<Grid item xs={2} className={classes.whiteBackground} />
			<Grid item xs={4} className={classes.whiteBackground}>
				{plannedMaintenanceBannerFeature && (
					<PlannedMaintenanceBannerComponent
						className={classes.maintenanceBanner}
					/>
				)}
				<LoginForm />
			</Grid>
			<Grid item xs={2} className={classes.whiteBackground}>
				<PortalLinks />
			</Grid>
		</Grid>
	)

	const PortalLinks: React.FC = () => (
		<Box className={classes.otherPortalsLinks}>
			<Link
				underline={'none'}
				href={`https://hr${
					process.env.NODE_ENV === 'production' ? '' : '.dev'
				}.cyclis.be/${activeLanguage}/login`}
			>
				<CustomButton
					id={'login-purple-panel-hr-button'}
					className={classes.portalLink}
					type={'outlined'}
					text={t('LoginHRLink')}
				/>
			</Link>
			<Link
				underline={'none'}
				href={`https://my${
					process.env.NODE_ENV === 'production' ? '' : '.dev'
				}.cyclis.be/${activeLanguage}/login`}
			>
				<CustomButton
					id={'login-purple-panel-mycylis-button'}
					className={classes.portalLink}
					type={'outlined'}
					text={t('LoginMyCyclisLink')}
				/>
			</Link>
		</Box>
	)

	const PurpleSidePanel: React.FC = () => (
		<Box className={classes.purpleSidePanel}>
			<CyclisFiguresSmallSvg className={classes.cyclisFigures} />
			<CyclisLogoBikeLeaseSvg className={classes.logo} />
			<Box className={classes.purplePanelContent}>
				<Typography className={classes.purplePanelTitle} variant={'h2'}>
					{t('LoginPurpleTitle1')}
				</Typography>
				<Typography className={classes.purplePanelTitle} variant={'h2'}>
					{t('LoginPurpleTitle2')}
				</Typography>
				<Typography className={classes.purplePanelTextBlock} variant={'body1'}>
					{t('LoginPurpleTextBlock')}
				</Typography>
				<img
					src={'/images/login-desktop.png'}
					alt={'Desktop image'}
					className={classes.purplePanelImage}
				/>
			</Box>
		</Box>
	)

	const LoginForm: React.FC = () => (
		<Box className={classes.loginFormContainer}>
			<Box className={loginError ? '' : classes.loginFormTitleBox}>
				<Typography className={classNames(classes.welcomeTitle)} variant={'h3'}>
					{t('LoginTitle1')}
				</Typography>
				<Typography className={classNames(classes.welcomeTitle)} variant={'h3'}>
					{t('LoginTitle2')}
				</Typography>
			</Box>
			{loginError && (
				<ErrorMessageBox
					id="login-error-message-box"
					className={classes.errorMessageBox}
					errorMessage={t(loginError)}
				/>
			)}
			<Formik
				initialValues={formValues}
				validationSchema={loginSchema(t)}
				onSubmit={submitLogin}
			>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					errors,
					touched,
				}): ReactNode => (
					<form onSubmit={handleSubmit}>
						<CustomTextInput
							className={classNames(classes.inputField)}
							label={t('LoginEmailLabel')}
							error={(errors.username && touched.username) as boolean}
							helperText={(touched.username && errors.username) as string}
							helperTextId={errors.username && 'username-helper-text'}
							propsToDelegate={{
								placeholder: t('LoginEmailDefaultValue'),
								name: 'username',
								type: 'email',
								value: values.username,
								onChange: handleChange,
								onBlur: handleBlur,
								error: (errors.username && touched.username) as boolean,
								fullWidth: true,
							}}
						/>
						<PasswordInput
							className={classNames(classes.inputField)}
							label={t('LoginPasswordLabel')}
							secondLabel={t('LoginForgotPasswordLabel')}
							secondLabelId={'login-forgot-password-link'}
							error={(errors.password && touched.password) as boolean}
							helperText={(touched.password && errors.password) as string}
							helperTextId={errors.password && 'password-helper-text'}
							secondLabelHref={`/${activeLanguage}/forgot-password`}
							propsToDelegate={{
								placeholder: t('LoginPasswordDefaultValue'),
								name: 'password',
								type: 'password',
								value: values.password,
								onChange: handleChange,
								onBlur: handleBlur,
								error: (errors.password && touched.password) as boolean,
								fullWidth: true,
							}}
						/>
						<CustomButton
							id={'login-submit-button'}
							className={classes.loginButton}
							text={t('LoginButton')}
							propsToDelegate={{
								type: 'submit',
								disabled: loginLoading,
							}}
						/>
					</form>
				)}
			</Formik>
		</Box>
	)

	return (
		<TemplateContainer
			id={'login-container-template'}
			className={classes.purpleBackground}
		>
			<DesktopGrid />
		</TemplateContainer>
	)
}

Login.propTypes = {}

export default Login
