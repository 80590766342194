import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE} from '@styles/mui-theme'

/**
 * Component-scoped styles which should be used as a hook
 * inside Login functional component
 */

const useStyles = makeStyles((theme) => ({
	/**
	 * Left purple panel
	 */
	cyclisFigures: {
		marginTop: theme.spacing(-9),
		height: `calc(100% + ${theme.spacing(9)}px)`,
		marginLeft: theme.spacing(-3),
		position: 'relative',
	},
	logo: {
		position: 'absolute',
		width: theme.spacing(112.2 / 8),
		top: theme.spacing(35 / 8),
		left: theme.spacing(25 / 8),
	},
	purplePanelContent: {
		position: 'absolute',
		top: theme.spacing(142 / 8),
		color: '#FFF',
	},
	purplePanelTitle: {
		margin: theme.spacing(0, 4),
		fontSize: 48,
		fontWeight: 'bold',
	},
	purplePanelTextBlock: {
		margin: theme.spacing(3, 4),
		fontSize: 16,
		fontWeight: 500,
		width: `calc(90% - ${LARGE_GUTTER_SIZE}px)`,
		color: '#dadada',
		lineHeight: 'normal',
		letterSpacing: 0.19,
	},
	purplePanelButton: {
		margin: theme.spacing(0, 4),
		color: '#fff',
	},
	purplePanelImage: {
		width: '100%',
		marginTop: theme.spacing(4 / 8),
	},

	/**
	 * Right purple panel
	 */
	loginFormContainer: {
		marginTop: '17vh',
	},
	loginFormTitleBox: {
		marginBottom: theme.spacing(4),
	},
	welcomeTitle: {
		fontSize: '1.5rem',
		fontWeight: 'bold',
	},
	errorMessageBox: {
		margin: theme.spacing(3, 0),
	},
	inputField: {
		marginBottom: theme.spacing(2),
	},
	loginButton: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(1.2, 7),
		width: `calc(50% - ${LARGE_GUTTER_SIZE}px)`,
	},

	/**
	 * Container
	 */
	purpleSidePanel: {
		position: 'relative',
		height: '100vh',
	},
	whiteBackground: {
		zIndex: 1,
		backgroundColor: 'white',
		height: '100vh',
	},
	purpleBackground: {
		backgroundColor: theme.palette.primary.main,
		overflowY: 'hidden',
	},
	otherPortalsLinks: {
		position: 'absolute',
		top: 32,
		right: 32,
		display: 'flex',
		justifyContent: 'flex-end',
		gap: 12,
	},
	portalLink: {
		backgroundColor: 'rgba(255, 255, 255, 0)',

		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.1)',
		},

		'&:active': {
			backgroundColor: 'rgba(255, 255, 255, 0.2)',
		},
	},
	maintenanceBanner: {
		marginTop: theme.spacing(96 / 8),
	},
}))

export default useStyles
